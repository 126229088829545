<template>
  <StepComponent>
    <PackageSelection />
  </StepComponent>
</template>

<script>
import StepComponent from "@/components/samples/SampleWizardSteps/StepComponent";
import PackageSelection from "@/components/samples/SampleWizardSteps/SampleStepComponents/PackageSelection";
import { mapActions } from "vuex";

export default {
  name: "PackageSelectionStep",
  components: { StepComponent, PackageSelection },
  methods: {
    ...mapActions("samples", ["updateSampleSet"]),
    beforeContinue() {
      return this.updateSampleSet("package").then(res => {
        return !!res;
      });
    }
  }
};
</script>

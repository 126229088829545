<template>
  <BaseCard
    title="General Sample Information"
    :step-component="'SampleInformationStep'"
    class="mb-5"
  >
    <div class="review">
      <div v-if="farm_id">
        Farm Name: <span>{{ (getFarmByID(farm_id) || {}).title }}</span>
      </div>
      <div v-if="source_other">
        Other Farm Name/ID: <span>{{ source_other }}</span>
      </div>
      <div v-if="quick_feed_type">
        Quick Feed Type: <span>{{ quick_feed_type.title }}</span>
      </div>
      <div v-if="feed_class">
        Feed Category:
        <span>{{
          (getById("feed_classes", feed_class) || {}).title || "N/A"
        }}</span>
      </div>
      <div v-if="feed_type_id">
        Feed Type:
        <span>{{
          (getById("feed_types", feed_type_id) || {}).title || "N/A"
        }}</span>
      </div>
      <div v-if="description">
        Description: <span>{{ description }}</span>
      </div>
      <div v-if="is_preground">
        Pregound?: <span>{{ is_preground }}</span>
      </div>
      <div v-if="dry_matter_method">
        Dry Matter Method: <span>{{ dry_matter_method }}</span>
      </div>
      <div v-if="grind_method">
        Grind Method: <span>{{ grind_method }}</span>
      </div>
      <div v-if="grind_size">
        Grind Size: <span>{{ grind_size }}</span>
      </div>
      <div v-if="date_collected">
        Date Collected:
        <span>{{ date_collected | formatDate }}</span>
      </div>
      <div v-if="date_harvested">
        Harvest Date:
        <span>{{ date_harvested | formatDate }}</span>
      </div>
      <div v-if="cutting">
        Cutting: <span>{{ cutting }}</span>
      </div>
      <div v-if="linked_samples[0]">
        Linked Samples:
        <ul>
          <li v-for="item in linked_samples" :key="item">
            Reference ID: <span>{{ item.id }}</span>
          </li>
        </ul>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/samples/ReviewStepCards/BaseCard";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import dayjs from "dayjs";

export default {
  name: "GeneralSampleInformation",
  components: { BaseCard },
  computed: {
    ...mapFields("samples", [
      "active_sample.farm_id",
      "active_sample.feed_type_id",
      "active_sample.description",
      "active_sample.is_preground",
      "active_sample.grind_size",
      "active_sample.dry_matter_method",
      "active_sample.grind_method",
      "active_sample.date_collected",
      "active_sample.date_harvested",
      "active_sample.cutting",
      "active_sample.is_linked",
      "active_sample.linked_samples",
      "active_sample.source_other"
    ]),
    ...mapFields("sampleWizard", [
      "wizard.quick_feed_type",
      "wizard.feed_class"
    ]),
    ...mapGetters("baseData", ["getById"]),
    ...mapGetters("farms", { getFarmByID: "getById" })
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return dayjs(String(value)).format("MM/DD/YYYY");
      }
    }
  }
};
</script>

<style scoped></style>

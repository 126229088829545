<template>
  <StepComponent>
    <v-row>
      <v-col>
        <LabSelection />
        <BillingInformation />
        <GeneralSampleInformation />
        <SamplingInformation />
      </v-col>
      <v-col>
        <ReviewImages />
        <PackagesAndComponents />
        <Price />
      </v-col>
    </v-row>
  </StepComponent>
</template>

<script>
import StepComponent from "@/components/samples/SampleWizardSteps/StepComponent";
import LabSelection from "@/components/samples/ReviewStepCards/LabSelection";
import BillingInformation from "@/components/samples/ReviewStepCards/BillingInformation";
import GeneralSampleInformation from "@/components/samples/ReviewStepCards/GeneralSampleInformation";
import SamplingInformation from "@/components/samples/ReviewStepCards/SamplingInformation";
import PackagesAndComponents from "@/components/samples/ReviewStepCards/PackagesAndComponents";
import ReviewImages from "@/components/samples/ReviewStepCards/ReviewImages";
import Price from "@/components/samples/ReviewStepCards/Price";

export default {
  name: "ReviewStep",
  components: {
    ReviewImages,
    StepComponent,
    LabSelection,
    BillingInformation,
    GeneralSampleInformation,
    SamplingInformation,
    PackagesAndComponents,
    Price
  }
};
</script>

<template>
  <v-card>
    <v-card-title>Add Feed Source</v-card-title>
    <v-container>
      <v-form ref="form" v-model="valid" lazy-validation>
        <SourceEditFormFields></SourceEditFormFields>
        <v-row class="pa-5">
          <v-btn @click="close()" color="secondary">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="save()" color="primary">
            Save
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapMutations, mapState } from "vuex";
import SourceEditFormFields from "@/components/sources/SourceEditFormFields";

export default {
  name: "SourceAddDialog",
  components: { SourceEditFormFields },
  props: {
    dialog: Boolean
  },
  data() {
    return {
      valid: false
    };
  },
  computed: {
    ...mapFields("sources", ["detailItem.farm_id", "detailItem.title"]),
    ...mapFields("samples", ["active_sample.farms_source_id"]),
    ...mapState("samples", ["active_sample"]),
    ...mapState("sources", ["detailItem"])
  },
  methods: {
    ...mapActions("sources", ["addFarmSource"]),
    ...mapMutations("sources", ["clearDetailItem"]),
    save() {
      if (!this.$refs.form.validate()) return false;
      this.farm_id = this.active_sample.farm_id;
      this.addFarmSource(this.detailItem).then(() => {
        this.farms_source_id = this.detailItem.id;
        this.clearDetailItem();
        this.close();
      });
    },
    close() {
      this.$emit("update:dialog", false);
    }
  },
  mounted() {
    this.clearDetailItem();
  }
};
</script>

<style scoped></style>

<template>
  <BaseCard
    title="Billing Information"
    :step-component="'BillingStep'"
    class="mb-5"
  >
    <div class="review">
      Business Name: <span>{{ billingAccount.title }}</span>
      <br />
      Name:
      <span>
        {{ billingAccount.first_name }} {{ billingAccount.last_name }}
      </span>
      <br />
      Address: <span>{{ billingAccount.address_1 }}</span>
      <br />
      <div v-if="billingAccount.address_2">
        Address 2: <span>{{ billingAccount.address_2 }}</span>
        <br />
      </div>
      City: <span>{{ billingAccount.city }}</span>
      <br />
      <div v-if="billingAccount.state_id">
        State, Region or Province:
        <span>{{
          (getById("states", billingAccount.state_id) || {}).title
        }}</span>
        <br />
      </div>
      Country:
      <span>{{
        (getById("states", billingAccount.country_id) || {}).title
      }}</span>
      <br />
      Postal Code: <span>{{ billingAccount.postal_code }}</span>
      <br />
      Phone Number: <span>{{ billingAccount.work_phone }}</span>
      <br />
      <div v-if="firstEmail">
        Email:
        <span>{{ firstEmail }}</span>
      </div>
      <div
        v-if="
          active_sample && active_sample.copy_to && active_sample.copy_to.length
        "
      >
        Report Recipients:
        <ul class="py-2">
          <li
            v-for="item in active_sample.copy_to"
            :key="item.id"
            class="font-weight-regular"
          >
            {{
              (copyToById(item.id, "copy_to_id") || {}).copy_to.email || "N/A"
            }}
          </li>
        </ul>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/samples/ReviewStepCards/BaseCard";
import { mapGetters, mapState } from "vuex";

export default {
  name: "BillingInformation",
  components: { BaseCard },
  computed: {
    ...mapState("samples", ["billingAccount", "active_sample"]),
    ...mapGetters("samples", ["firstEmail"]),
    ...mapGetters("baseData", ["getById"]),
    ...mapGetters("copyTo", { copyToById: "getById" })
  }
};
</script>

<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <LoadingBar
      v-if="loading === true || error.message.length"
      :error="error.message"
    />
    <v-stepper v-model="currentStep" vertical v-else>
      <v-stepper-items>
        <template v-for="(step, n) in steps">
          <v-stepper-step
            :key="`step-${n}`"
            :complete="currentStep > n"
            :step="n + 1"
            class="py-2"
          >
            {{ step.title }}
          </v-stepper-step>
          <v-stepper-content :key="`step-content-${n}`" :step="n + 1">
            <component
              v-bind:is="step.component"
              :ref="step.component"
              v-if="currentStep === n + 1"
              :halt="halt"
            />
            <div class="v-footer no-gutters">
              <LoadingBar v-if="loading === true" />
              <template v-else>
                <v-row no-gutters dense>
                  <v-col>
                    <v-btn
                      v-if="currentStep > 1"
                      color="primary"
                      @click="previousStep"
                    >
                      Previous
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col>
                    <v-btn
                      v-if="routeSampleId && currentStep !== steps.length"
                      color="primary"
                      @click="saveAndExit(n + 1)"
                      :disabled="!canContinue"
                      class="float-right mb-1 ml-2"
                    >
                      Save and Exit
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="nextStep(n + 1)"
                      :disabled="!canContinue"
                      class="float-right mb-1 ml-2"
                    >
                      {{ nextStepBtnLabel }}
                    </v-btn>
                    <v-btn
                      v-if="!routeSampleId && currentStep === steps.length"
                      @click="addAnotherSample()"
                      color="primary"
                      class="float-right mb-1 ml-2"
                    >
                      Add Another Sample
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </div>
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>
    <v-dialog v-model="refIdReminder" width="500" persistent>
      <v-card>
        <v-card-title>Please Note</v-card-title>
        <v-card-text>
          <p><b>Ref ID:</b> {{ active_sample.id }}</p>
          <p>
            <b>Sample bag barcode:</b>
            {{ active_sample.sample_bag_barcode || "(None)" }}
          </p>
          Please include the Ref ID on your paperwork or sample bag if you have
          not included a sample bag barcode.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="refIdReminder = false">Cancel</v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="nextStep(currentStep)"
            :disabled="!canContinue"
            class="mb-1 ml-2"
          >
            Add to Shipment
          </v-btn>
          <v-btn
            v-if="routeSampleId && currentStep !== steps.length"
            color="primary"
            @click="saveAndExit(currentStep)"
            :disabled="!canContinue"
            class="mb-1 ml-2"
          >
            Save and Exit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import LoadingBar from "@/components/loaders/LoadingBar";
import PackageSelectionStep from "@/components/samples/SampleWizardSteps/PackageSelectionStep";
import BillingStep from "@/components/samples/SampleWizardSteps/BillingStep";
import SampleInformationStep from "@/components/samples/SampleWizardSteps/SampleInformationStep";
import ReviewStep from "@/components/samples/SampleWizardSteps/ReviewStep";
import ShipmentSelectionStep from "@/components/samples/SampleWizardSteps/ShipmentSelectionStep";
import ImagingStep from "@/components/samples/SampleWizardSteps/ImagingStep";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import hasIn from "lodash.hasin";

/**
 * @property {int} currentStep
 */
export default {
  name: "SampleWizard",
  components: {
    LoadingBar,
    PackageSelectionStep,
    BillingStep,
    SampleInformationStep,
    ReviewStep,
    ShipmentSelectionStep,
    ImagingStep
  },
  data() {
    return {
      loading: false,
      refIdReminder: false,
      valid: false, // This gets updated automatically, via the validator
      halt: false // Allows a child component to halt continue by emitting updateHalt
    };
  },
  computed: {
    ...mapGetters("sampleWizard", ["steps", "currentStepComponentTitle"]),
    ...mapState("samples", ["active_sample"]),
    ...mapFields("sampleWizard", [
      "wizard.currentStep",
      "wizard.returnStep",
      "wizard.error"
    ]),
    ...mapFields("samples", ["billingAgree"]),
    // Returns the current step component instance
    currentStepComponent() {
      if (!this.$refs) return undefined;
      // Find the current component (by ref="componentName")
      // currentStep is ahead of the index by 1
      let component = this.$refs[this.currentStepComponentTitle];
      if (!component) return undefined;
      if (Array.isArray(component)) {
        // The component will most likely be in an array, but it'll only have one item in it
        component = component[0];
      }
      return component;
    },
    canContinue() {
      if (this.error.message.length) {
        return false;
      }
      return this.valid;
    },
    nextStepBtnLabel() {
      if (this.currentStep === this.steps.length) return "Finished";
      else if (this.currentStepComponentTitle === "ReviewStep")
        return "Add to Shipment";
      return "Continue";
    },
    activeSampleShipmentId() {
      return this.active_sample && this.active_sample.shipment
        ? this.active_sample.shipment.id
        : null;
    },
    routeSampleId() {
      return hasIn(this.$route, "params.sampleId")
        ? this.$route.params.sampleId
        : null;
    }
  },
  methods: {
    ...mapActions("samples", ["setActive"]),
    ...mapActions("baseData", [
      "getLabs",
      "getTestPackageCategories",
      "getPackages",
      "getComponentCategories",
      "getTestComponents",
      "getFeedTypes",
      "getFeedClasses",
      "getCalibrateIngredients"
    ]),
    ...mapActions("farms", ["getFarms"]),
    ...mapMutations("samples", ["clearActive"]),
    ...mapMutations("sampleWizard", ["resetWizard"]),
    previousStep() {
      this.currentStep = this.currentStep - 1;
    },
    // Proceed to the next step
    nextStep(n, cb = undefined) {
      // If validation doesn't succeed, we can stop now
      if (!this.$refs.form.validate()) return false;
      if (this.currentStepComponentTitle === "ReviewStep")
        this.refIdReminder = !this.refIdReminder;
      if (this.refIdReminder) return false;
      // If component has beforeContinue method, execute it. Otherwise, we can safely set to true.
      const beforeContinue =
        this.currentStepComponent && this.currentStepComponent.beforeContinue
          ? this.currentStepComponent.beforeContinue()
          : true;
      // Resolve as a promise, that way we can do async things when needed
      Promise.resolve(beforeContinue).then(res => {
        // As long as we don't have a false response, we can proceed to the next step
        if (res) {
          // If returnStep contains a step, go to that step
          if (this.returnStep && typeof this.returnStep === "number") {
            this.currentStep = this.returnStep;
            this.returnStep = false; // Reset returnStep value
            return true;
          }
          // Default to next step iteration
          this.currentStep = n + 1;

          if (this.currentStep > this.steps.length) {
            const shipmentId = this.activeSampleShipmentId;
            // Sample submitted. Clear active_sample and reset wizard
            this.clearActive();
            this.resetWizard();
            // Only issue redirect if a callback wasn't defined
            if (typeof cb !== "function") {
              this.$router.push({
                path: "/shipments/browse",
                query: { id: shipmentId }
              });
            }
          }
          // execute the callback if one was provided
          if (typeof cb === "function") cb();
        }
      });
    },
    saveAndExit(step) {
      this.nextStep(step, () => {
        this.clearActive();
        this.resetWizard();
        this.$router.go(-1);
      });
    },
    addAnotherSample() {
      this.nextStep(this.steps.length, () => {
        this.setActive(null);
      });
    }
  },
  mounted() {
    this.loading = true;
    this.error.message = "";

    if (this.routeSampleId) {
      this.billingAgree = true;
    }

    Promise.all([
      this.getLabs(),
      this.getTestPackageCategories(),
      this.getPackages(),
      this.getComponentCategories(),
      this.getTestComponents(),
      this.getFarms(),
      this.getFeedTypes(),
      this.getFeedClasses(),
      this.getCalibrateIngredients(),
      // Initialize active sample
      this.setActive(this.routeSampleId)
    ]).then(data => {
      // Check for errors from any promise
      if (data.includes(false)) {
        this.error.message = "An error occurred while retrieving this sample";
      }
      this.loading = false;
    });
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Test Packages")]),_c('v-list',_vm._l((_vm.test_package_categories),function(package_category){return _c('v-list-group',{key:package_category.name,staticClass:"elevation-1",attrs:{"prepend-icon":package_category.action,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                    _vm.getPackageTitleWithCount(
                      package_category.name,
                      package_category.id
                    ).toUpperCase()
                  )}})],1)]},proxy:true}],null,true),model:{value:(package_category.active),callback:function ($$v) {_vm.$set(package_category, "active", $$v)},expression:"package_category.active"}},[_c('v-list-item',[[_c('v-data-table',{attrs:{"headers":_vm.test_package_headers,"items":_vm.getPackageOnCategoryId(package_category.id),"disable-pagination":true,"item-key":"id","show-select":"","hide-default-footer":true,"noDataText":"There are no test packages to select from."},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return undefined},proxy:true}],null,true),model:{value:(_vm.test_packages),callback:function ($$v) {_vm.test_packages=$$v},expression:"test_packages"}})]],2)],1)}),1)],1)],1),(_vm.selectedClient.report_calibrate)?_c('v-row',[_c('v-col',[_c('PackageCalibrate')],1)],1):_vm._e(),(_vm.selectedClient.report_grand_valley)?_c('v-row',[_c('v-col',[_c('PackageGrandValley')],1)],1):_vm._e(),(_vm.selectedClient.report_best_mix)?_c('v-row',[_c('v-col',[_c('PackageBestMix')],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Test Components")]),_c('v-list',_vm._l((_vm.component_categories),function(category){return _c('v-list-group',{key:category.title,staticClass:"elevation-1",attrs:{"prepend-icon":category.action,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                    _vm.getTitleWithCount(
                      category.title,
                      category.id
                    ).toUpperCase()
                  )}})],1)]},proxy:true}],null,true),model:{value:(category.active),callback:function ($$v) {_vm.$set(category, "active", $$v)},expression:"category.active"}},[_c('v-list-item',[[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.test_components_formatted(category.id),"disable-pagination":true,"item-key":"id","show-select":"","hide-default-footer":true,"noDataText":"There are no test components to select from."},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function({
                      isSelected,
                      select,
                      item
                    }){return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"color":"primary","disabled":item.in_selected_package},on:{"input":function($event){return select($event)}}})]}},{key:"item.package",fn:function({ item }){return [_vm._v(" "+_vm._s(item.in_selected_package ? "Included in Package" : "")+" ")]}},{key:"item.price",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getComponentStoredPrice(item.id, item.price))+" ")]}}],null,true),model:{value:(_vm.test_components_proxy),callback:function ($$v) {_vm.test_components_proxy=$$v},expression:"test_components_proxy"}})]],2)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <h3>Test Packages</h3>
          <v-list>
            <v-list-group
              v-for="package_category in test_package_categories"
              :key="package_category.name"
              v-model="package_category.active"
              :prepend-icon="package_category.action"
              no-action
              class="elevation-1"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="
                      getPackageTitleWithCount(
                        package_category.name,
                        package_category.id
                      ).toUpperCase()
                    "
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item>
                <template>
                  <v-data-table
                    v-model="test_packages"
                    :headers="test_package_headers"
                    :items="getPackageOnCategoryId(package_category.id)"
                    :disable-pagination="true"
                    item-key="id"
                    show-select
                    :hide-default-footer="true"
                    noDataText="There are no test packages to select from."
                  >
                    <template #header.data-table-select></template>
                  </v-data-table>
                </template>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-col>
      </v-row>
      <v-row v-if="selectedClient.report_calibrate">
        <v-col>
          <PackageCalibrate />
        </v-col>
      </v-row>
      <v-row v-if="selectedClient.report_grand_valley">
        <v-col>
          <PackageGrandValley />
        </v-col>
      </v-row>
      <v-row v-if="selectedClient.report_best_mix">
        <v-col>
          <PackageBestMix />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Test Components</h3>
          <v-list>
            <v-list-group
              v-for="category in component_categories"
              :key="category.title"
              v-model="category.active"
              :prepend-icon="category.action"
              no-action
              class="elevation-1"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="
                      getTitleWithCount(
                        category.title,
                        category.id
                      ).toUpperCase()
                    "
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item>
                <template>
                  <v-data-table
                    v-model="test_components_proxy"
                    :headers="headers"
                    :items="test_components_formatted(category.id)"
                    :disable-pagination="true"
                    item-key="id"
                    show-select
                    :hide-default-footer="true"
                    noDataText="There are no test components to select from."
                  >
                    <template
                      v-slot:item.data-table-select="{
                        isSelected,
                        select,
                        item
                      }"
                    >
                      <v-simple-checkbox
                        :value="isSelected"
                        color="primary"
                        :disabled="item.in_selected_package"
                        @input="select($event)"
                      />
                    </template>
                    <template v-slot:item.package="{ item }">
                      {{
                        item.in_selected_package ? "Included in Package" : ""
                      }}
                    </template>
                    <template v-slot:item.price="{ item }">
                      {{ getComponentStoredPrice(item.id, item.price) }}
                    </template>
                  </v-data-table>
                </template>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import rules from "@/utils/rules";
import { mapGetters, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import PackageCalibrate from "@/components/samples/SampleWizardSteps/SampleStepComponents/PackageCalibrate";
import PackageBestMix from "@/components/samples/SampleWizardSteps/SampleStepComponents/PackageBestMix";
import PackageGrandValley from "@/components/samples/SampleWizardSteps/SampleStepComponents/PackageGrandValley";

export default {
  name: "PackageSelection",
  components: { PackageCalibrate, PackageBestMix, PackageGrandValley },
  data() {
    return {
      testComponentStoredPricing: [],
      rules: {
        required: [rules.required]
      },
      headers: [
        {
          text: "",
          value: "package"
        },
        {
          text: "Component Name",
          value: "title"
        },
        { text: "Description", value: "description" },
        { text: "Price", value: "price" },
        { text: "International Price", value: "price_international" }
      ],
      test_package_headers: [
        {
          text: "",
          value: "package"
        },
        {
          text: "Package Name",
          value: "title"
        }
      ]
    };
  },
  computed: {
    ...mapFields("samples", [
      "active_sample.test_packages",
      "active_sample.test_components",
      "active_sample.lab_id",
      "adjusted_test_components"
    ]),
    ...mapGetters("baseData", [
      "test_package_categories",
      "component_categories"
    ]),
    ...mapGetters("samples", ["filterById", "adjusted_test_components"]),
    ...mapGetters("clients", ["selectedClient"]),
    ...mapGetters("baseData", {
      getById: "getById",
      allTestPackages: "test_packages"
    }),
    test_components_formatted() {
      return test_component_type_id => {
        return this.filterById(
          "adjusted_test_components",
          test_component_type_id,
          "test_component_type_id"
        ).map(item => {
          return {
            ...item,
            in_selected_package: !!this.package_components.find(
              i => i.id === item.id
            )
          };
        });
      };
    },
    test_components_proxy: {
      get() {
        // Return full test component objects
        return this.adjusted_test_components.filter(item =>
          this.test_components.find(i => i.id === item.id)
        );
      },
      set(newVal) {
        // Only include the ID in the destination object
        this.test_components = newVal.map(item => ({ id: item.id }));
      }
    },
    test_package_one: {
      get() {
        return this.test_packages.length ? this.test_packages[0].id : null;
      },
      set(newVal) {
        if (!Array.isArray(newVal)) {
          newVal = newVal ? [{ id: newVal }] : [];
        }
        if (this.test_package_two) {
          newVal.push({ id: this.test_package_two });
        }
        this.test_packages = newVal;
      }
    },
    test_package_two: {
      get() {
        return this.test_packages.length > 1 ? this.test_packages[1].id : null;
      },
      set(newVal) {
        if (!Array.isArray(newVal)) {
          newVal = newVal ? [{ id: newVal }] : [];
        }
        if (this.test_package_one) {
          newVal.unshift({ id: this.test_package_one });
        }
        this.test_packages = newVal;
      }
    },
    package_components() {
      // Return array of test components included in package(s)
      return [
        ...this.adjusted_test_components.filter(
          item =>
            !!item.test_packages.find(
              i =>
                i.id === this.test_package_one || i.id === this.test_package_two
            )
        )
      ];
    }
  },
  methods: {
    ...mapActions("baseData", ["getCalibrateIngredients"]),
    getTitleWithCount(title, catId) {
      const filterByCatId = item => item.test_component_type_id === catId;
      const packageCount = this.package_components.filter(filterByCatId).length;
      const selectedCount = this.test_components_proxy.filter(filterByCatId)
        .length;
      return (
        title +
        (packageCount > 0 ? ` [ ${packageCount} Included in Package ]` : "") +
        (selectedCount > 0 ? ` [ ${selectedCount} Selected ]` : "")
      );
    },
    getPackageTitleWithCount(title, catId) {
      let selectedIds = this.test_packages.map(a => a.id);
      const selectedCount = this.getPackageOnCategoryId(catId).filter(
        item =>
          item.test_package_category_id === catId &&
          selectedIds.includes(item.id)
      ).length;

      return (
        title + (selectedCount > 0 ? ` [ ${selectedCount} Selected ]` : "")
      );
    },
    // If a lab limit has been set and this samples lab exists is not limited by the package, return package
    getLabLimitedPackages() {
      let results = this.allTestPackages.filter(item => {
        if (
          item.labs.length === 0 ||
          item.labs.filter(lab => lab.id === this.lab_id).length > 0
        ) {
          return item;
        }
      });
      return results;
    },
    getPackageOnCategoryId(category_id) {
      return this.getLabLimitedPackages().filter(item => {
        if (item.test_package_category_id === category_id) {
          return item;
        }
      });
    },
    getComponentStoredPrice(testComponentId, price) {
      this.test_components.forEach(function(testComponent) {
        if (testComponent.id === testComponentId) {
          if (testComponent._joinData) {
            price = testComponent._joinData.price;
          }
        }
      });

      return price;
    }
  },
  watch: {
    package_components(newVal) {
      // Always make sure package components are de-selected
      const ids = newVal.map(item => item.id);
      this.test_components_proxy = this.test_components_proxy.filter(
        item => ids.indexOf(item.id) === -1
      );
    }
    // test_packages(val, oldVal) {
    //   if (this.test_packages.length > 2) {
    //     // Remove the new item
    //     let difference = val.filter(x => !oldVal.includes(x));
    //     if(difference.length > 0) {
    //       const index = this.test_packages.findIndex(testPackage => {
    //         return testPackage.id === difference[0].id;
    //       });
    //       this.test_packages.splice(index, 1);
    //     }
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.v-application--is-ltr .v-list-group--no-action {
  & > .v-list-group__items > .v-list-item {
    padding: 0;
  }
}

.v-data-table {
  width: 100%;
}
</style>

<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-autocomplete
          :items="farms"
          :rules="rules.required"
          label="Farm Name"
          no-data-text="No farms to select from"
          item-text="title"
          item-value="id"
          v-model="farm_id"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" v-if="farm_id">
        <v-autocomplete
          :items="farms"
          :rules="rules.alphanumericRequired"
          label="Farm ID"
          no-data-text="No farms to select from"
          item-text="id"
          item-value="id"
          v-model="farm_id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2">
        <v-dialog v-model="farmDialog" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on">Add Farm</v-btn>
          </template>
          <FarmAddDialog v-bind:dialog.sync="farmDialog"></FarmAddDialog>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-autocomplete
          :items="sources"
          label="Feed Sources"
          no-data-text="No feed sources to select from"
          item-text="title"
          item-value="id"
          v-model="farms_source_id"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" v-if="farm_id">
        <v-dialog v-model="sourceDialog" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on">Add Source</v-btn>
          </template>
          <SourceAddDialog v-bind:dialog.sync="sourceDialog"></SourceAddDialog>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-select
          :items="getQuickFeedTypes"
          label="Quick Feed Types"
          no-data-text="No quick feed types to select from"
          item-text="title"
          item-value="id"
          v-model="quick_feed_type"
          :disabled="!!farms_source_id"
          v-on:input="populateFeedFields"
          return-object
          clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-select
          :items="feedTypesByClass"
          :rules="rules.required"
          label="Feed Type"
          no-data-text="No feed types to select from"
          item-text="title"
          item-value="id"
          v-model="feed_type_id"
          :disabled="!!quick_feed_type"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          :items="feed_classes"
          :rules="rules.required"
          label="Feed Category"
          no-data-text="No feed categories to select from"
          item-text="title"
          item-value="id"
          v-model="feed_class_id"
          :disabled="!!quick_feed_type"
          clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="12">
        <v-textarea
          v-model="description"
          label="Description"
          rows="2"
          :rules="rules.description"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="is_sample_bag"
          label="Sample Bag Barcode"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="is_sample_bag === true" dense>
      <v-col cols="12" sm="4">
        <v-text-field
          ref="bagbarcode"
          v-model="sample_bag_barcode"
          label="Bar Code"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-checkbox label="Is Fresh" v-model="is_fresh" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-checkbox
          v-model="is_linked"
          label="Is this sample linked to another for testing? (This is only selected if the first sample is already logged.)"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="is_linked" dense>
      <v-col>
        <v-alert dense type="info">
          Linked samples include samples logged for apparent nutrient
          digestibility testing, mixer evaluations, duplicate samples, etc.
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="is_linked" dense>
      <LoadingBar v-if="loading === true" />
      <v-col>
        <LinkedSamples />
      </v-col>
    </v-row>
    <v-row v-if="selectedClient.is_grand_valley === true" dense>
      <div class="h6 py-2 font-weight-bold">Grand Valley</div>
    </v-row>
    <v-row v-if="selectedClient.is_grand_valley === true" dense>
      <v-col cols="4">
        <v-text-field
          v-model="gv_sales_rep_name"
          label="Sales Rep. Name"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="gv_sales_rep_number"
          label="Sales Rep. Number"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="gv_file_number"
          label="File Number"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="selectedClient.is_wallensteins === true" dense>
      <div class="h6 py-2 font-weight-bold">Wallensteins</div>
    </v-row>
    <v-row v-if="selectedClient.is_wallensteins === true" dense>
      <v-col cols="4">
        <v-text-field
          v-model="wallensteins_req_id"
          label="Requester ID"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import rules from "@/utils/rules";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import LoadingBar from "@/components/loaders/LoadingBar";
import SourceAddDialog from "@/components/sources/SourceAddDialog";
import LinkedSamples from "@/components/samples/SampleWizardSteps/SampleStepComponents/LinkedSamples";
import { validId } from "@/utils";
import FarmAddDialog from "@/components/farms/FarmAddDialog";

export default {
  name: "GeneralSampleInformation",
  components: {
    FarmAddDialog,
    SourceAddDialog,
    LoadingBar,
    LinkedSamples
  },
  data() {
    return {
      loading: false,
      cuttingOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      dateCollected: {
        label: "Date Collected",
        field: "date_collected"
      },
      dateHarvested: {
        label: "Harvest Date",
        field: "date_harvested"
      },
      rules: {
        required: [rules.required],
        alphanumericRequired: [rules.required, rules.alphanumeric],
        description: [rules.maxLength(60)]
      },
      farmDialog: false,
      sourceDialog: false
    };
  },
  computed: {
    ...mapFields("sampleWizard", [
      "wizard.feed_class",
      "wizard.quick_feed_type"
    ]),
    ...mapGetters("clients", ["selectedClient"]),
    ...mapFields("samples", [
      "active_sample.farm_id",
      "active_sample.farms_source_id",
      "active_sample.feed_type_id",
      "active_sample.description",
      "active_sample.is_preground",
      "active_sample.is_fresh",
      "active_sample.grind_size",
      "active_sample.dry_matter_method",
      "active_sample.grind_method",
      "active_sample.date_collected",
      "active_sample.date_harvested",
      "active_sample.cutting",
      "active_sample.is_linked",
      "active_sample.linked_samples",
      "active_sample.is_sample_bag",
      "active_sample.sample_bag_barcode",
      "active_sample.gv_file_number",
      "active_sample.gv_sales_rep_name",
      "active_sample.gv_sales_rep_number",
      "active_sample.wallensteins_req_id"
    ]),
    ...mapState("sources", { farmSource: "detailItem" }),
    ...mapGetters("baseData", [
      "filterById",
      "getQuickFeedTypes",
      "feed_types",
      "feed_classes"
    ]),
    ...mapGetters("sources", { sources: "getActiveItems" }),
    ...mapGetters("farms", { farms: "getActiveItems" }),
    feed_class_id: {
      get() {
        // If there's a feed_class defined, use that
        if (validId(this.feed_class)) return this.feed_class;
        // Otherwise, check if a feed_type was selected and use its feed class
        if (this.feed_type_id) {
          const feed_type = this.feed_types.find(
            item => item.id === this.feed_type_id
          );
          if (feed_type) return feed_type.feed_class_id;
        }
        // Otherwise, we don't have a value to return
        return null;
      },
      set(newVal) {
        // When clearing the value, also clear the feed type. This is a filter field after all.
        if (newVal === null) this.feed_type_id = null;
        // Always update the local feed_class, since feed_class_id is computed on the entity
        this.feed_class = newVal;
      }
    },
    feedTypesByClass() {
      return !this.feed_class_id
        ? this.feed_types
        : this.feed_types.filter(
            item => item.feed_class_id === this.feed_class_id
          );
    }
  },
  methods: {
    ...mapActions("sources", ["getFarmSource", "getFarmSources"]),
    ...mapActions("samples", ["getSamples"]),
    ...mapMutations("samples", ["setActiveSampleCopyTo"]),
    populateFeedFields(val) {
      if (val) {
        this.feed_class_id = val.feed_class_id;
        this.feed_type_id = val.id;
      } else {
        this.feed_class_id = null;
        this.feed_type_id = null;
      }
    },
    updateDateField(field, value) {
      this[field] = value;
    },
    search(val) {
      return val && val !== this.value && this.loading !== true;
    },
    isLinkedPopulate() {
      if (this.is_linked) {
        this.loading = true;
        // @TODO: this needs to be updated to bring samples into the component that are needed, because samples will be paginated
        this.getSamples().finally(() => {
          this.loading = false;
        });
      } else {
        this.linked_samples = [];
      }
    },
    sampleBagBarcodeFocus() {
      this.$nextTick(() => {
        if (this.$refs.bagbarcode) {
          this.$refs.bagbarcode.focus();
        }
      });
    }
  },
  watch: {
    is_linked: function() {
      this.isLinkedPopulate();
    },
    search(val) {
      return val && val !== this.value && this.loading !== true;
    },
    farm_id(farmId) {
      if (farmId) this.getFarmSources(farmId);
    },
    farms_source_id(farmsSourceId, previousSourceId) {
      if (!farmsSourceId) return;
      this.getFarmSource({
        farm_id: this.farmId,
        source_id: farmsSourceId
      }).then(() => {
        this.quick_feed_type = null;
        this.populateFeedFields({
          feed_class_id: this.farmSource.feed_class_id,
          id: this.farmSource.feed_type_id
        });
        this.farmSource.copy_tos.forEach(copyTo => {
          this.setActiveSampleCopyTo(copyTo.id);
        });
      });
      const previousSource = this.sources.find(
        source => source.id === previousSourceId
      );
      if (
        !this.description ||
        (previousSource && this.description === previousSource.title)
      ) {
        const source = this.sources.find(source => source.id === farmsSourceId);
        this.description = source ? source.title : "";
      }
    },
    is_sample_bag: function() {
      this.sampleBagBarcodeFocus();
    }
  },
  mounted() {
    this.isLinkedPopulate();
  }
};
</script>

<template>
  <div>
    <step-component>
      <shipment-select
        v-model="shipmentId"
        lab_id="activeSampleLabId"
        initial-info="Most recent open shipment is selected, to choose a different open select the down arrow."
        :show-create-new="true"
      />
    </step-component>
  </div>
</template>

<script>
import StepComponent from "@/components/registration/AccountSetupSteps/StepComponent";
import ShipmentSelect from "@/components/shipments/ShipmentSelect";
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import hasIn from "lodash.hasin";
export default {
  name: "ShipmentSelectionStep",
  components: { ShipmentSelect, StepComponent },
  data: () => ({
    shipmentId: null
  }),
  computed: {
    ...mapFields("samples", {
      activeSampleShipmentId: "active_sample.shipment.id"
    }),
    ...mapState("samples", ["active_sample"]),
    activeSampleLabId() {
      return this.active_sample ? this.active_sample.lab_id : null;
    },
    routeSampleId() {
      return hasIn(this.$route, "params.sampleId")
        ? this.$route.params.sampleId
        : null;
    }
  },
  methods: {
    ...mapActions("samples", ["updateSample"]),
    ...mapActions("shipments", [
      "createShipment",
      "getShipment",
      "deleteShipment"
    ]),
    async beforeContinue() {
      if (this.shipmentId === this.activeSampleShipmentId) {
        return Promise.resolve(true);
      }
      const shipment = !this.shipmentId
        ? await this.createShipment()
        : { id: this.shipmentId };
      return await this.updateSample({
        id: this.active_sample.id,
        lab_id: this.activeSampleLabId,
        shipments: [
          {
            id: shipment.id,
            lab_id: this.activeSampleLabId
          }
        ]
      }).then(async res => {
        // check to see if the originally assigned shipment no longer has any samples
        if (this.activeSampleShipmentId) {
          const originalShipment = await this.getShipment(
            this.activeSampleShipmentId
          );
          if (originalShipment && originalShipment.samples.length === 0) {
            await this.deleteShipment(originalShipment.id);
          }
        }
        this.activeSampleShipmentId = shipment.id;
        return !!res;
      });
    }
  },
  mounted() {
    if (!this.activeSampleShipmentId && this.active_sample.shipments) {
      this.activeSampleShipmentId = this.active_sample.shipments[0].id;
    }
    this.shipmentId = this.activeSampleShipmentId;
  }
};
</script>

<style scoped></style>

<template>
  <BaseCard
    title="Sampling Information"
    :step-component="'SampleInformationStep'"
  >
    <div class="review">
      <div v-if="sampler_name">
        Sampler Name: <span>{{ sampler_name }}</span>
      </div>
      <div v-if="is_certified">
        Certified: <span>{{ is_certified ? "Yes" : "No" }}</span>
      </div>
      <div v-if="certified_protocol">
        Certified Protocol:
        <span>{{ certified_protocol ? "Yes" : "No" }}</span>
      </div>
      <div v-if="sampling_device">
        Sampling Device: <span>{{ sampling_device }}</span>
      </div>
      <div v-if="number_of_cores_bales">
        No. of Cores / Bales: <span>{{ number_of_cores_bales }}</span>
      </div>
      <div v-if="estimated_dry_matter">
        Estimated Dry Matter: <span>{{ estimated_dry_matter }}</span>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/samples/ReviewStepCards/BaseCard";
import { mapFields } from "vuex-map-fields";

export default {
  name: "SamplingInformation",
  components: { BaseCard },
  computed: {
    ...mapFields("samples", [
      "active_sample.sampler_name",
      "active_sample.is_certified",
      "active_sample.certified_protocol",
      "active_sample.sampling_device",
      "active_sample.number_of_cores_bales",
      "active_sample.estimated_dry_matter"
    ])
  }
};
</script>

<style scoped></style>

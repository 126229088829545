<template>
  <BaseCard title="Lab" :step-component="'BillingStep'" class="mb-5">
    <div class="review" v-if="lab_id">
      Lab Name:
      <span>{{ (getById("labs", lab_id) || {}).title || "N/A" }}</span>
      <br />
      Lab ID: <span>{{ lab_id }}</span>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/samples/ReviewStepCards/BaseCard";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "LabSelection",
  components: { BaseCard },
  computed: {
    ...mapFields("samples", ["active_sample.lab_id"]),
    ...mapGetters("baseData", ["getById"])
  }
};
</script>

<style scoped></style>

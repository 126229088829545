<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="12">
        <v-text-field
          label="Sampler Name"
          v-model="sampler_name"
        ></v-text-field>
      </v-col>
    </v-row>
    <template v-if="sampler_name">
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-checkbox
            v-model="is_certified"
            label="Is Sampler Certified?"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-checkbox
            v-model="certified_protocol"
            label="Is this sample taken in accordance with a defined protocol?"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="sampling_device"
            label="Sampling Device"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import rules from "@/utils/rules";
import { mapFields } from "vuex-map-fields";

export default {
  name: "SamplingInformation",
  data() {
    return {
      rules: {
        required: [rules.required],
        alphanumericRequired: [rules.required, rules.alphanumeric]
      }
    };
  },
  computed: {
    ...mapFields("samples", [
      "active_sample.sampler_name",
      "active_sample.is_certified",
      "active_sample.certified_protocol",
      "active_sample.sampling_device",
      "active_sample.number_of_cores_bales",
      "active_sample.estimated_dry_matter"
    ])
  }
};
</script>

<template>
  <BaseCard title="Images" step-component="ImagingStep" class="mb-5">
    <v-container>
      <SampleImages :sample-id="active_sample.id" read-only />
    </v-container>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/samples/ReviewStepCards/BaseCard";
import SampleImages from "@/components/samples/components/SampleImages";
import { mapState } from "vuex";
export default {
  name: "ReviewImages",
  components: { BaseCard, SampleImages },
  computed: {
    ...mapState("samples", ["active_sample"])
  }
};
</script>

<style scoped></style>

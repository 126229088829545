import { render, staticRenderFns } from "./ShipmentSelectionStep.vue?vue&type=template&id=29496364&scoped=true"
import script from "./ShipmentSelectionStep.vue?vue&type=script&lang=js"
export * from "./ShipmentSelectionStep.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29496364",
  null
  
)

export default component.exports